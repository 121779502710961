import {energyWorksData} from "./energyWorksData"

const classPrefix = "js-productWorldMap";

export default initMapChanger
async function initMapChanger() {
  const imgNames = getImageNameList()
  const imgList = await preloadMaps(imgNames)
  $(()=> {
    // This event must set in the listener after searchInput events.
    setTimeout(()=> {
      $(`.${classPrefix}`).on("change", "input[name=category], input[name=product], input[name=application]", function () {
        changeImage(imgList, getImageKey())
      });
    }, 5)
  })
}

function getImageKey() {
  const keys = []
  keys.push($(`.${classPrefix} input[name=category]:checked`).val())
  const prod = $(`.${classPrefix} input[name=product]:checked`).val()
  if (prod) {
    keys.push(prod)
    const app = $(`.${classPrefix} input[name=application]:checked`).val()
    if (app) {
      keys.push(app)
    }
  }
  return keys.join('-')
}

function changeImage(imgList, imgName) {
  if (imgList[imgName]) {
    const areaClass = `${classPrefix}__areamap`
    const tmpClass = `${classPrefix}__areamapTmp`
    $(`.${areaClass}`).addClass(tmpClass)

    const $img = $(imgList[imgName]).clone().css('display', 'none').addClass(areaClass)

    $(`.${tmpClass}`).fadeOut('normal', function() {
        $(this).remove()
    })
    $(`.${classPrefix}__map`).append($img)
    $img.ready(() => $img.fadeIn('normal'))
  }
}

async function preloadMaps(list) {
  const result = {}
  await Promise.all(list.map(async (name)=> {
    const src = await checkImageExists(name)
    const img = new Image()
    img.src = src
    result[name] = img
  }))
  return result
}

async function checkImageExists(name) {
  const checkUrl = `/_assets/img/energy/works/product_map/map-${name}.png`
  return new Promise((resolve) => {
    $.ajax(checkUrl)
    .done(()=> resolve(checkUrl))
    .fail(async ()=> {
      const nameParts = name.split('-')
      if (nameParts.length === 1) {
        resolve(`/_assets/img/energy/works/worldmap_init.png`)
      } else {
        const parentName = nameParts.filter((_, i)=> i !== (nameParts.length -1)).join("-")
        resolve(await checkImageExists(parentName))
      }
    })
  })
}

function getImageNameList() {
  const urlList = []
  Object.keys(energyWorksData).map(cat => {
    urlList.push(cat)
    Object.keys(energyWorksData[cat]).map(prod => {
      urlList.push(`${cat}-${prod}`)
      Object.keys(energyWorksData[cat][prod]).map(app => {
        if (app && app !== "none") {
          urlList.push(`${cat}-${prod}-${app}`)
        }
      })
    })
  })
  return urlList
}
