/*=======================================================
    言語切替時のリダイレクト

    1. /product/ページで言語切り替えを行った場合、/en/product/, /ch/product/へ遷移する
    2. 遷移先のURLが存在しない場合は、その言語ページのトップにリダイレクトする
    日本語：https://kobelco-machinery-energy.com（AWS）
    英語  ：https://kobelco-machinery-energy.com/en/（AWS）
    中国語：https://kobelco-machinery-energy.cn （Alibaba）

=======================================================*/

const targets = $('.js-switchLanguage');
let nextUrl = '';
const awsHostName = 'www.kobelco-machinery-energy.com';
const awsDomain = 'https://' + awsHostName;
const alibabaHostName = 'www.kobelco-machinery-energy.cn';
const alibabaDomain = 'https://' + alibabaHostName;


targets.on('click', (e) => {
    e.preventDefault();

    // 現在の言語
    let locationHost = location.hostname;
    let locationPath = location.pathname.split('/');
    locationPath = locationPath.filter( (path) => path !== '');

    let currentLanguage = '';
    if (locationHost === alibabaHostName) {
        currentLanguage = 'chinese';
    } else if (locationPath.indexOf('en') > -1) {
        currentLanguage = 'english';
    } else {
        currentLanguage = 'japanese';
    }

    // 切り替える言語
    const targetLanguage = $(e.currentTarget).data('language')

    // 遷移するURLを設定
    // 日本語ページが起点の場合
    if (currentLanguage === 'japanese'){
        if (targetLanguage === 'english') {
            // japanese to english
            nextUrl = '/en' + location.pathname;
        } else if (targetLanguage === 'chinese') {
            // japanese to chinese

            //nextUrl = alibabaDomain + location.pathname;
            //中国語サイトで検索ページが表示されるまでの一時的処理
            if (location.pathname.indexOf("/search") === -1) {
                nextUrl = alibabaDomain + location.pathname;
            } else {
                nextUrl = alibabaDomain;
            }
        }
    }

    // 英語ページが起点の場合
    if (currentLanguage === 'english'){
        if (targetLanguage === 'japanese') {
            // english to japanese
            nextUrl = location.pathname.replace('/en', '');
        } else if (targetLanguage === 'chinese') {
            // english to chinese

            //nextUrl = alibabaDomain + location.pathname.replace('/en', '');
            //中国語サイトで検索ページが表示されるまでの一時的処理
            if (location.pathname.indexOf("/search") === -1) {
                nextUrl = alibabaDomain + location.pathname.replace('/en', '');
            } else {
                nextUrl = alibabaDomain;
            }
        }
    }

    // 中国語ページが起点の場合
    if (currentLanguage === 'chinese'){
        if (targetLanguage === 'japanese') {
            // chinese to japanese
            nextUrl = awsDomain + location.pathname;
        } else if (targetLanguage === 'english') {
            // chinese to english
            nextUrl = awsDomain + '/en' + location.pathname;
        }
    }

    location.assign(nextUrl);
});
