import { products, applications, energyWorksData} from "./energyWorksData"
import { isEn, isCn } from "../i18nUtil"

const classPrefix = "js-productWorldMap";

export default initSearchInput
function initSearchInput() {
  $(()=> {
    $(`.${classPrefix}`).on("change", "input[name=category]", function () {
      changeCategory($(this).val());
    });
    $(`.${classPrefix}`).on("change", "input[name=product]", function () {
      changeProduct($(this).val());
    });
  })
}

function changeCategory(val) {
  const wrapClass = classPrefix + "__category";
  $(`.${wrapClass}__product`).remove();
  $(`.${wrapClass}__application`).remove();

  const children = energyWorksData[val];
  const items = Object.keys(children).reduce((prv, key) => {
    if (products[key]) {
      prv.push({
        key,
        name: products[key],
      });
    }
    return prv;
  }, []);

  if (items.length > 0) {
    const inputs = inputGroupTemplate("product", isEn()?"Product":isCn()?"产品":"製品", items);
    appendInputs(inputs)
  }
}

function changeProduct(val) {
  const wrapClass = classPrefix + "__category";
  $(`.${wrapClass}__application`).remove();
  const category = $(
    `.${classPrefix} input[name="category"]:checked`
  ).val();

  const children = energyWorksData[category][val];
  if (!children.none) {
    const items = Object.keys(applications).filter(key=> key !== "none").map((key) => ({
      key,
      name: applications[key],
    }));
    const visibleItems = Object.keys(children);
    if (items.length > 0) {
      const inputs = inputGroupTemplate(
        "application",
        isEn()?"Application":isCn()?"用途":"用途",
        items,
        visibleItems
      );
      appendInputs(inputs)
    }
  }
}

function appendInputs(inputs) {
  const $inputs = $(inputs).css('opacity', 0);
  $(".js-productWorldMap__categories").append($inputs)
  $inputs.ready(()=> $inputs.fadeTo(150, 1))
}

function inputGroupTemplate(key, name, items, visibleItems = []) {
  const wrapClass = classPrefix + "__category";
  return `
        <li class="${wrapClass}__${key}">
            <h5 class="page-energy_works__map__categoryName">${name}</h5>
            <ul class="page-energy_works__map__categoryItems">
            ${items
                .map(
                  (item, num) => `
                    <li class="u-generalRadioWrap --energy">
                        <input type="radio" id="${key}_${num}" name="${key}" value="${item.key}" ${
                        visibleItems.length > 0 &&
                        !visibleItems.includes(item.key)
                            ? "disabled"
                            : ""
                    }/>
                        <label for="${key}_${num}">${item.name}</label>
                    </li>`
                )
                .join("")}
            </ul>
        </li>`;
}
