//=======================================================
//
// ナビの開閉(sp)
//
//=======================================================

const accordionTrigger = $('.js-trg-acd');
const triggerSearch = $('.l-header__searchTrigger');

// toggle アコーディオン
accordionTrigger.on('click', function(){
    const currentTrigger = $(this); // js-trg
    const currentLv = $(this).next('.l-header__navItem');
    const currentLvNo = $(this).data('level');

    if (!currentTrigger.hasClass('active')){
        // 開く
        // 同階層のアコーディオンを閉じる
        closeSameLvAccordion(currentTrigger, currentLv);
        // 下層のアコーディオンを閉じる
        closeUnderAccordion(currentLv);
        // クリックされた要素を開く
        openAccordion(currentTrigger, currentLv);
    } else {
        // 閉じる
        // 下層のアコーディオンを閉じる
        closeUnderAccordion(currentLv);
        // クリックされた要素を閉じる
        closeAccordion(currentTrigger, currentLv);
    }

    // 最下層クリックで全て閉じる
    if (currentLvNo == '3'){
        closeAllAccordion();
    }
});

// 検索フォームが開いた時全てのアコーディオンを閉じる
triggerSearch.on('click', function(){
    closeAllAccordion();
})

// 指定のアコーディオンを開く
function openAccordion(trigger, level){
    trigger.addClass('active');
    level.addClass('isOpen');
    level.slideDown();
}

// 指定のアコーディオンを閉じる
function closeAccordion(trigger, level){
    trigger.removeClass('active');
    level.removeClass('isOpen');
    level.slideUp();
}

// 下層のアコーディオンを閉じる
function closeUnderAccordion(level){
    const childHasActive = level.find('.active');
    const childHasOpen = level.find('.isOpen');
    closeAccordion(childHasActive, childHasOpen);
}

// 同階層のアコーディオンを閉じる
function closeSameLvAccordion(trigger, level){
    // triggerのチェック
    let triggerClassName = trigger.parent().attr('class');
    triggerClassName = triggerClassName.split(' ')[1];
    const targetTrigger = $('.' + triggerClassName + ' p.active');

    // levelのチェック
    let levelClassName = level.attr('class');
    levelClassName = levelClassName.split(' ')[1];
    const targetLevel = $('.' + levelClassName + '.isOpen');

    closeAccordion(targetTrigger, targetLevel);
}

// 全てのアコーディオンを閉じる（level3 click時、ナビゲーションを閉じる時）
function closeAllAccordion(){
    const targetsTrigger = $('.l-header__nav .active');
    const targetsLevel = $('.l-header__nav .isOpen');
    closeAccordion(targetsTrigger, targetsLevel);
}
