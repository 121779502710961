//=======================================================
//
// 画面スクロールに伴う制御
//
//=======================================================

const headerPc = $('.l-headerPc');
const headerSp = $('.l-headerSp');
const linkFixed = $('.l-fixedLink');

$(window).on('scroll', function(){
    const windowPos = $(window).scrollTop();

    // ヘッダー：追従
    if (windowPos > 20){
        headerPc.addClass('isFixed');
        headerSp.addClass('isFixed');
        linkFixed.addClass('isFixed');
    } else {
        headerPc.removeClass('isFixed');
        headerSp.removeClass('isFixed');
        linkFixed.removeClass('isFixed');
    }

    // トップに戻るボタン：フッターの上で固定(PCのみ)
    const btnScrollTop = $('.c-btnScrollTop');
    let footerHeight = $('footer').offset().top;
    if ($('body').hasClass('cookieConfirm')) {
        const cookieConfirmHeight = document.documentElement.style.getPropertyValue('--cookieConfirmHeight').replace('px','');
        footerHeight = footerHeight + parseInt((cookieConfirmHeight?cookieConfirmHeight:0), 10);
    }
    if (windowPos + $(window).outerHeight() > footerHeight){
        btnScrollTop.addClass('isBottom');
    } else {
        btnScrollTop.removeClass('isBottom');
    }

});

