//=======================================================
//
// MT サイトサーチ
//
//=======================================================

window.MTNetSearchDataLayer = window.MTNetSearchDataLayer || [];
function MTNetSearch() { return window.MTNetSearchDataLayer.push(arguments); }
MTNetSearch("registerHandler", function(data) {
    // SP検索フォーム
    var secondarySearchForm = document.getElementById("mt-site-search-container2");
    var input = secondarySearchForm.getElementsByTagName("input")[0];
    input.value = data.query;

    // 検索ページフォーム
    const searchPageInput = document.querySelector('input.page-search__searchBox__searchInput')
    if (searchPageInput) {
        searchPageInput.value = data.query
    }
});
