//=======================================================
//
// お知らせの制御
//
//=======================================================
import { isEn } from "./i18nUtil"

if ($('.page-news')){
    const baseUrl = `${isEn()?"/en":""}/news/`
    const selectYear = $('#news_year');

    // 年別アーカイブのページ遷移
    selectYear.on('change', function() {
        const year = $(this).val() === 'recent' ? '' : $(this).val();
        location.assign(`${baseUrl}${year}`);
    })

    // 初期値の設定
    const currentYear = parseInt(location.pathname.replace(baseUrl, '').replace('/',''));
    const yearValue = isNaN(currentYear) ? 'recent' : String(currentYear);
    selectYear.val(yearValue);
}
