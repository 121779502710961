//=======================================================
//
// 他言語化用関数
//
//=======================================================

export const isEn = () => {
    if (location.href.includes("/en/")) {
        return true
    }
    return false
}

export const isCn = () => {
    if (location.href.includes("kobelco-machinery-energy.cn")) {
        return true
    } else {
        const h = document.querySelector("html").getAttribute("lang")
        if (h === "zh") return true
    }
    return false
}
