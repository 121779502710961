//=======================================================
//
// アンカーリンクのsmooth scroll
//
//=======================================================

// ページ内スクロールリンク
$('a[href*=\\#]').on('click', function() {
    const hashArray = $(this.hash);
    const hashId = $(this.hash)[0].id;
    if (hashArray.length > 0) {
        hashArray.each(function () {
            // modal起動のリンクを除く
            if (!hashId.startsWith('modal')){
                $('html, body').animate({scrollTop: $(this).offset().top - 80}, 600);
            }
        })
    }
});

// 別ページからの遷移した場合
if (location.hash) {
    var anchor = $(location.hash).offset();
    if (anchor) {
        $('html, body').scrollTop(0).animate({scrollTop: anchor.top - 80}, 600);
    }
}
