//=======================================================
//
// Energy / works の世界地図
//
//=======================================================
import searchInput from "./searchInput"
import mapChanger from "./mapChanger.js"
import mapPinModal from "./mapPinModal.js"
import "./style.scss"

if (location.href.includes("/energy/works/")) {
  searchInput()
  mapPinModal()
  mapChanger()
}
