//=======================================================
//
// お問い合わせフォームの制御
//
//=======================================================
import { isEn, isCn } from "./i18nUtil"


// 添付ファイルの制御
// ==========================
// 初期表示
$(window).on('load',function(){
    checkFileSelected();
})

// ブラウザバック時の動作
function registBrowserBack(cb) {
    const pnav = performance.getEntriesByType("navigation")
    pnav.forEach(v=> {
        if (v.type === "back_forward") {
            cb()
        }
    })
}
registBrowserBack(()=> {
    $(()=> {
        $('.u-formFileInner input').val('');
        clearFileInputs();
        checkFileSelected();
    })
})


// 添付ファイル変更時
$('body').on('change', '.u-formFileInner input' , function() {
    const changeFile = $(this);
    const fileName = $(this).next('.u-formFileName');
    if (changeFile.val()) {
        fileName.html(changeFile.prop('files')[0].name);
    } else {
        fileName.html(isEn()?'No file selected':isCn()?'未选择任何文件':'ファイルが選択されていません')
    }
    checkFileSelected();
});

// 追加ボタン
$('body').on('click', '.u-formFileAdd button' , function(e) {
    e.preventDefault();
    const maxNum = 5
    const fileLen = $('.u-formFile').length
    if ( fileLen >= maxNum - 1) {
        $('.u-formFileAdd').hide()
    }
    if ( fileLen >= maxNum) {
        return
    }
    let fileNo = 0
    const numbers = []
    $('.u-formFile input[type="file"]').each((_,elm)=> {
        const i = parseInt($(elm).attr('id').replace("file", ""), 10)
        numbers.push(i)
        if (i > fileNo) fileNo = i
    })
    for (let i = 0;0 < maxNum;i++) {
        if (!numbers.includes(i)) {
            fileNo = i
            break
        }
    }
    const newInput =
        `<div class="u-formFile">
        <div class="u-formFileInner">
        <input type="file" name="file${fileNo}" id="file${fileNo}" accept="application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/pdf, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, image/jpeg, image/png, application/zip, application/x-zip-compressed">
        <span class="u-formFileName">${isEn()?'No file selected':isCn()?'未选择任何文件':'ファイルが選択されていません'}</span>
        <span class="u-formFileDelete">${isEn()?'Delete':isCn()?'删除':'削除する'}</span>
        </div>
        <label for="file${fileNo}" class="u-formFileAttach">${isEn()?'Select a file':isCn()?'选择文件':'ファイルを選択する'}</label>
        </div>`
    $('.u-formFileWrap').append(newInput);
    checkFileSelected();
});

// 削除ボタン
$('body').on('click', '.u-formFileDelete' , function() {
    $(this).prev().prev('input').val("");
    $(this).prev('.u-formFileName').html(isEn()?'No file selected':isCn()?'未选择任何文件':'ファイルが選択されていません');
    const fileForm = $('.u-formFile');
    if (fileForm.length > 1) {
        $(this).parent().parent('.u-formFile').remove();
    }
    checkFileSelected();
    if ( $('.u-formFile').length < 5) {
        $('.u-formFileAdd').css("display", "")
    }
});

function clearFileInputs() {
    $('.u-formFile').each(function(i) {
        if (i > 0) {
            $(this).remove()
        }
        $('.u-formFileAdd').css("display", "")
    })
}

// チェック
function checkFileSelected() {
    // 1つ目のファイル：ファイルが選択されていない時は削除ボタンを非表示
    // 2つ目以降のファイル：削除ボタンは常に表示
    const targetFile = $('.u-formFileInner input');
    targetFile.each(function(index){
        if (index === 0){
            const file = $(this);
            if (file.val()){
                file.removeClass('--empty')
            } else {
                file.addClass('--empty')
            }
        }
    })
    // ファイルが選択されていないinputが存在する時はattachボタンを非表示
    let isFileSelected = true;
    targetFile.each(function(){
        if (!$(this).val()){
            isFileSelected = false;
        }
    })
    if (isFileSelected) {
        $('.u-formFileAdd').addClass('--isShow');
    } else {
        $('.u-formFileAdd').removeClass('--isShow');
    }
}




// streamの制御
// ==========================

// select連動
if ($('.u-formSelect select')) {
    $('body').on('change', '.u-formSelect select', function(){
        const unitSelectors = $('.u-formSelect select');
        const unitGroup = $(this).attr('data-unit');
        if (!unitGroup) return
        const unitValue = $(this).val();
        unitSelectors.each(function() {
            const selector = $(this);
            if (selector.attr('data-unit') === unitGroup) {
                selector.val(unitValue);
            }
        });
    })
}

// streamの追加
if ($('.u-formAddStream')) {
    $('.u-formAddStream button').on('click', function(e){
        e.preventDefault();
        createNewStreamEvent()
    })
}

function createNewStreamEvent() {
    let streamName = '';
    let streamReplaceCharacter = '';
    switch ($('.js-stream').length) {
        case 2:
            streamName = 'Stream C';
            streamReplaceCharacter = 'c_';
            break;
        case 3:
            streamName = 'Stream D';
            streamReplaceCharacter = 'd_';
            break;
        case 4:
            streamName = 'Stream E';
            streamReplaceCharacter = 'e_';
            break;
        case 5:
            streamName = 'Stream F';
            streamReplaceCharacter = 'f_';
            break;
        case 6:
            streamName = 'Stream G';
            streamReplaceCharacter = 'g_';
            $('.u-formAddStream').hide();
            break;
        default:
            break;
    }
    if (streamName !== '' && streamReplaceCharacter !== '') {
        const newStream = createNewStream(streamName, streamReplaceCharacter);
        newStream.insertBefore($('.u-formAddStream'));
    } else {
        $('.u-formAddStream').hide();
    }
}

// 追加 Streamの作成
function createNewStream(streamName, replaceCharacter) {
    const newStream = $('#js-stream-copy').clone(true);
    // idの削除
    newStream.attr('id','');
    // 見出しの置換
    newStream.children('h4').text(streamName);
    const newStreamInputs = newStream.find('input, select');
    newStreamInputs.each(function(){
        // select name属性のreplace
        const inputName = $(this).attr('name').replace('b_', replaceCharacter);
        $(this).attr('name', inputName);
        // select data-unit属性のreplace
        if ($(this).attr('data-unit')) {
            const inputUnit = $(this).attr('data-unit').replace('b_', replaceCharacter)
            $(this).attr('data-unit', inputUnit)
        }
        // valueをリセット
        if ($(this)[0].tagName.toLowerCase() === 'select'){
            if (!$(this).attr('data-unit') || /^\w_/.test($(this).attr('data-unit'))) {
                $(this).val($(this).children('option:first-child').val());
            } else {
                const unit = $(this).attr('data-unit')
                $(this).val($(`#js-stream-copy select[data-unit="${unit}"]`).val())
            }
        } else {
            $(this).val('')
        }
    })
    return newStream;
}

if($('.page-energy_inquiry').length) {
    $(()=> {
        $('form').submit(()=> {
            if ($('#contactConfirmForm').length) {
                sessionStorage.removeItem('energyInquiry')
            } else {
                const result = []
                $('.js-stream input[type!="radio"], .js-stream input:radio:checked, .js-stream select, .js-stream textarea').each((_,v)=> {
                    result.push({
                        name: $(v).attr('name'),
                        value: $(v).val(),
                    })
                })
                sessionStorage.setItem('energyInquiry', JSON.stringify(result))
            }
        })
    })

    registBrowserBack(()=> {
        $(()=> {
            const savedValues = sessionStorage.getItem('energyInquiry')
            if (!savedValues) return
            try {
                const parsedValues = JSON.parse(savedValues)
                const addedNumber = parsedValues.filter(v=> v.name.includes('_inletTemperature_text')).length
                for (let i=0;i < addedNumber - 2;i++) {
                    createNewStreamEvent()
                }
                parsedValues.map(v=> {
                    const $input = $(`input[name="${v.name}"], select[name="${v.name}"], textarea[name="${v.name}"]`)
                    if ($input.attr('type') === 'radio') {
                        $input.val([v.value])
                    } else {
                        $input.val(v.value)
                    }
                })
            } catch(e) {
                console.error(e)
            }
        })
    })
}

//autoFillRequiredInputs()
//function autoFillRequiredInputs() {
//    $('input[name="inquiry_type"][value="b"]').prop('checked', true)
//    $('input[name="name"]').val('name')
//    $('input[name="company"]').val('company')
//    $('input[name="mail"]').val('test@test.test')
//    $('input[name="mail-confirm"]').val('test@test.test')
//    $('input[name="tel"]').val('000-000-000')
//    $('textarea[name="inquiry"]').val('inquiry')
//}

// Compressorの制御
// ==========================
if($('.page-compressor_inquiry').length) {
    const addModel = newAddModelFnc()
    const addModelEvent = () => {
        if ($('.u-formBoxCompressor').length >= 5) {
            return
        }
        addModel()
        if ($('.u-formBoxCompressor').length >= 5) {
            $('.js-addModel').hide()
        }
    }

    $(()=> {
        $('.js-addModel button').on('click', e=>{
            e.preventDefault()
            addModelEvent()
        })

        const switchInquiryType = newSwitchInquiryTypeFnc()
        switchInquiryType($('input[name="inquiry_type"]:checked').val()||'a')
        $('input[name="inquiry_type"]').on('change', function(e) {
            e.preventDefault();
            switchInquiryType($(this).val())
        })

        $('form').submit(()=> {
            if ($('#contactConfirmForm').length) {
                sessionStorage.removeItem('compressorInquiry')
            } else {
                const result = []
                $('input[name^="model"][type!="radio"], input:radio[name^="model"]:checked, select[name^="model"], textarea[name^="model"]').each((_,v)=> {
                    result.push({
                        name: $(v).attr('name'),
                        value: $(v).val(),
                    })
                })
                sessionStorage.setItem('compressorInquiry', JSON.stringify(result))
            }
        })
    })

    registBrowserBack(()=> {
        $(()=> {
            const savedValues = sessionStorage.getItem('compressorInquiry')
            if (!savedValues) return
            try {
                const parsedValues = JSON.parse(savedValues)
                const addedNumber = parsedValues.filter(v=> v.name.includes('_area')).length
                for (let i=0;i < addedNumber - 1;i++) {
                    addModelEvent()
                }
                parsedValues.map(v=> {
                    const $input = $(`input[name="${v.name}"], select[name="${v.name}"], textarea[name="${v.name}"]`)
                    if ($input.attr('type') === 'radio') {
                        $input.val([v.value])
                    } else {
                        $input.val(v.value)
                    }
                })
            } catch(e) {
                console.error(e)
            }
        })
    })
}

function newAddModelFnc() {
    let x = 2;
    const tmpClass = 'js-addModel__template';

    return () => {
        const $t = $(`.${tmpClass}`).clone(true);
        $t.removeClass(tmpClass);
        const inputs = $t.find('input, select, textarea');
        const checkedRadio = []
        inputs.each(function(){
            const inputName = $(this).attr('name').replace('model1', `model${x}`);
            $(this).attr('name', inputName);

            if ($(this).data('unit')) {
                const inputUnit = $(this).attr('data-unit')
                $(this).attr('data-unit', `${inputUnit}_${x}`)
            }
            // valueをリセット
            if ($(this)[0].tagName.toLowerCase() === 'select'){
                if ($(this).children('option:selected')) {
                    $(this).val($(this).children('option:selected').val())
                } else {
                    $(this).val($(this).children('option:first-child').val());
                }
            } else if($(this).attr('type') === 'radio') {
                if (checkedRadio.filter(v=> v.name === $(this).attr('name')).length === 0) {
                    checkedRadio.push({
                        name: $(this).attr('name'),
                        value: $(this).val(),
                    })
                }
            } else {
                $(this).val('')
            }
        })
        $t.insertBefore($('.js-addModel'));
        checkedRadio.map(v=>{
            $(`input[name="${v.name}"]`).val([v.value])
        })
        x++;
    }
}

function newSwitchInquiryTypeFnc() {
    const typeList = []
    $('input[name="inquiry_type"]').each(function() {
        typeList.push(`.js-inquiryType--${$(this).val()}`)
    })
    return val => {
        console.log(val)
        typeList.map(type=> {
            $(type)
                .hide()
                .find('input,select,button,textarea').prop('disabled', true);
        })
        $(`.js-inquiryType--${val}`)
            .show()
            .find('input,select,button,textarea').prop('disabled', false);
    }
}


// プライバシーポリシーの同意
// ==========================
$(()=>{
    if ($('#privacyPolicy').length) {
        $('#privacyPolicy').prop('checked', false)
        $('.u-formSubmit button').prop('disabled', true);
    }
})
$('#privacyPolicy').on('change', function(){
    if ($(this).prop('checked')){
        $('.u-formSubmit button').prop('disabled', false);
    } else {
        $('.u-formSubmit button').prop('disabled', true);
    }
})
