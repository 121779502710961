//=======================================================
//
// アプリケーションマップ
//
//=======================================================
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';



$('.js-appmap__open').on('click', function(e) {
    closeAll()
    openModal($(this).attr('data-target'))
    disableBodyScroll('.js-appmap__modal, .js-appmap__modalContent')
    e.stopPropagation()
})

$('.js-appmap__close, .js-appmap__modal').on('click', function(e) {
    closeAll()
    e.stopPropagation()
    clearAllBodyScrollLocks()
})
$('.js-appmap__modal > *').on('click', function(e) {
    e.stopPropagation()
})

const openModal = (target) => {
    $(`.js-appmap__modal.js-appmap--${target}`).addClass('open')
}

const closeAll = () => {
    $('.js-appmap__modal').removeClass('open')
}
