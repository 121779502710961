//=======================================================
//
// ナビの開閉(pc)
//
//=======================================================

const navTrigger = $('.l-header__navParent');

const removeAll = () => {
    navTrigger.each((_, v) => {
        $(v).removeClass('isOpen')
    });
}

navTrigger.on('click', function(e){
    const mode = $(this).hasClass('isOpen')?'remove':'add'
    removeAll()
    if (mode === 'add') {
        $(this).addClass('isOpen');
    } else {
        $(this).removeClass('isOpen');
    }
    e.stopPropagation()
});

$('.js-headerClose').on('click', e => {
    removeAll()
    e.stopPropagation()
})

$('body').on('click', () => {
    removeAll()
})
