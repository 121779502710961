import { isEn, isCn } from "./i18nUtil";

document.addEventListener("DOMContentLoaded", () => {
    const elms = document.querySelectorAll(".js-catalogDownload");
    elms.forEach((elm) => {
        elm.addEventListener("click", (e) => {
            const form = e.currentTarget.closest("form");
            const checked = form.querySelectorAll('input[type="checkbox"][name^="entry"]:checked');

            removeEnergyParamInput()
            if (checked.length) {
                if (e.currentTarget.closest("section").id === 'energy') {
                    appendEnergyParamInput(form);
                }
                form.submit();
            } else {
                if (isEn()) {
                    alert("Please select at least one.");
                } else if (isCn()) {
                    alert("请选择一项或多项。");
                } else {
                    alert("一つ以上選択してください。");
                }
            }
        });
    });

    document.querySelectorAll(".js-catalogSingleDownload").forEach((elm) => {
        elm.addEventListener("click", (e) => {
            e.preventDefault();
            const form = e.currentTarget.closest("form");
            form.querySelectorAll('input[name$="[checkDownload]"]').forEach((elm) => (elm.checked = false));

            removeEnergyParamInput()

            e.currentTarget.parentElement.querySelector('input[name$="[checkDownload]"]').checked = true;

            if (e.currentTarget.closest('section').id && e.currentTarget.closest('section').id  === 'energy') {
                appendEnergyParamInput(form)
            }

            form.submit();
        });
    });
});

const appendEnergyParamInput = (form) => {
    const input = document.createElement("input");
    input.type = "hidden";
    input.name = "energy_dlbtn";
    input.value = 1;
    form.appendChild(input);
};
const removeEnergyParamInput = () => {
    const energyParamInput = document.querySelector('input[name="energy_dlbtn"]')
    if (energyParamInput) {
        energyParamInput.remove()
    }
};
