const toggleEvent = category => {
    const $inputs = $(`.js-history_filterInput[value="${category}"]`)
    if ( 0 === $inputs.length) return

    if ($inputs.first().prop('checked')) {
        $(`.js-history_item--${category}`).show()
    } else {
        $(`.js-history_item--${category}`).hide()
    }
}
const resetYears = () => {
    $('.js-history_list > li').each((_, year) => {
        let itemNum = 0
        $(year).find('li[class*="js-history_item"]').each((_, event) => {
            if ('none' != $(event).css('display')) {
                itemNum++
            }
        })
        if (itemNum === 0) {
            $(year).hide()
        } else {
            $(year).show()
        }
    })
}

$(()=> {
    resetYears()

    $('.js-history_filterInput').on('change', function() {
        toggleEvent($(this).val())
        resetYears()
    })
})
