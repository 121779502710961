//=======================================================
//
// ナビとサイト内検索の開閉
//
//=======================================================
import {
    disableBodyScroll,
    clearAllBodyScrollLocks
  } from "body-scroll-lock";
  
const headerMenu = document.getElementById('js-nav');
const headerSearch = document.getElementById('js-search');

const triggerGrobal = document.querySelector('.l-header__head');
const triggerMenu = document.querySelectorAll('.c-navTrigger');
const triggerSearch = document.querySelectorAll('.l-header__searchTrigger');

// toggle ナビゲーション
triggerMenu.forEach((trigger) => {
    trigger.addEventListener("click", () => {
        if (!headerMenu.classList.contains("isOpen")) {
            // header navが開いていない場合：開く
            if(headerSearch.classList.contains("isOpen")){
                closeSearch();
                openNav();
            } else {
                openNav();
                disableBodyScroll(headerMenu);
            }
        } else {
            // header navが既に開いている場合：閉じる
            closeNav();
            clearAllBodyScrollLocks();
        }
    });
})

// toggle 検索
triggerSearch.forEach((trigger) => {
    trigger.addEventListener("click", () => {
        if (!headerSearch.classList.contains("isOpen")) {
            // header searchが開いていない場合
            if(headerMenu.classList.contains("isOpen")){
                closeNav();
                openSearch();
            } else {
                openSearch();
                disableBodyScroll(headerMenu);
            }
        } else {
            // header secrchが既に開いている場合
            closeSearch();
            clearAllBodyScrollLocks();
        }
    });
})


// ナビ 開く
function openNav(){
    triggerMenu.forEach((trigger) => {
        trigger.classList.add('isOpen');
    });
    headerMenu.classList.add('isOpen');
    triggerGrobal.classList.add('isOpen');
}
// ナビ 閉じる
function closeNav(){
    triggerMenu.forEach((trigger) => {
        trigger.classList.remove('isOpen');
    });
    headerMenu.classList.remove('isOpen');
    triggerGrobal.classList.remove('isOpen');
    // 閉じる時に位置をトップに戻す
    setTimeout(() => {
        headerMenu.scrollTop = 0;
    }, 550);
}

// 検索 開く
function openSearch(){
    triggerSearch.forEach((trigger) => {
        trigger.classList.add('isOpen');
    });
    headerSearch.classList.add('isOpen');
    triggerGrobal.classList.add('isOpen');
}
// 検索 閉じる
function closeSearch(){
    triggerSearch.forEach((trigger) => {
        trigger.classList.remove('isOpen');
    });
    headerSearch.classList.remove('isOpen');
    triggerGrobal.classList.remove('isOpen');
}
