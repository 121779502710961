//=======================================================
//
// iframeの高さ調整（iframe呼び出し側）
//
//=======================================================

(function() {
    $(()=> {
        document.querySelectorAll('iframe').forEach(elm=> {
            elm.contentWindow.postMessage({
                type: 'iframeLoaded',
            })
        })
    })

    window.addEventListener("message", e => {
        if (e.data.type === "frameInit") {
            $('body,html').scrollTop(0);
        }

        if (e.data.type === "adjustHeight" && !isNaN(e.data.value)) {
            $(e.source.frameElement).height(Number(e.data.value))
        }
    }, false)


    // 樹脂機械事業：ラジオボタンの初期値設定
    // ================================
    if ($('.page-resin_inquiry').length){
        let inquiryType = 'estimate';
        const targetUrl = [
            {
                url: '/resin/solution/',
                type: 'test'
            }
        ]
        if (document.referrer) {
            const referrer = new URL(document.referrer);

            targetUrl.forEach((urlInfo) => {
                if (urlInfo.url === referrer.pathname){
                    inquiryType = urlInfo.type;
                }
            })
        }
        const target =
            inquiryType === 'estimate' ? 0 :
            inquiryType === 'test' ? 1 :
            inquiryType === 'kneading' ? 2 :
            inquiryType === 'other' ? 3 :
            0 ;

        document.querySelector('iframe[src*="resin/inquiry/contact_form"]')
            .contentWindow.postMessage({
                type: 'inputUpdate',
                inputs: [
                    {
                        name: 'inquiry_type',
                        value: target,
                    },
                ],
            })
    }
})()
