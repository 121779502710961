import {destinations, products, energyWorksData} from "./energyWorksData"
import { isEn, isCn } from "../i18nUtil"

const classPrefix = "js-productWorldMap";

export default initMapPinModal
function initMapPinModal() {
  $(()=> {
    $(`.${classPrefix}`).on("change", "input[name=category], input[name=product], input[name=application]", function () {
      clearPins()
      clearCountryList()
      putPins()
      closeModal()
    });
    $(`.${classPrefix}`).on("click", `.${classPrefix}__mappin g`, function (e) {
      closeModal()
      $(this).parents(`.${classPrefix}__mappinWrap`).addClass('active')
      const destKey = $(this).parents(`.${classPrefix}__mappin`).attr('data-destination')
      const modalData = getModalData(destKey)
      openModal(modalData)

      e.stopPropagation()
    });
    $(`.${classPrefix}`).on("click", `.${classPrefix}__countryList > li`, function () {
      closeModal()
      const destKey = $(this).attr('data-destination')
      $(`.${classPrefix}__mappin[data-destination="${destKey}"]`).parents(`.${classPrefix}__mappinWrap`).addClass('active')
      const modalData = getModalData(destKey)
      openModal(modalData)
    });
    $(`.${classPrefix}__map`).on('click', function() {
      closeModal()
    })
    $(`.${classPrefix}__map`).on("click", `.${classPrefix}__modal`, function (e) {
      e.stopPropagation()
    });
    $(`.${classPrefix}__map`).on("click", `.${classPrefix}__modalClose`, function () {
      closeModal()
    });
  })
}

function putPins() {
  const cat = $(`.${classPrefix} input[name="category"]:checked`).val();
  const prod = $(`.${classPrefix} input[name="product"]:checked`).val();
  const app = $(`.${classPrefix} input[name="application"]:checked`).val();

  let list = {}
  if (!energyWorksData[cat]) {
    return
  }
  if (!energyWorksData[cat][prod]) {
    return
  }
  if (energyWorksData[cat][prod].none) {
    list = energyWorksData[cat][prod].none
  } else if (!energyWorksData[cat][prod][app]) {
    return
  } else {
    list = energyWorksData[cat][prod][app];
  }
  Object.keys(list).map(dest => {
    if (!destinations[dest]) {
      console.error(`${dest} is not found in destination list`)
    }
    putPin(destinations[dest])
    appendCountryList(destinations[dest])
  })
}

function clearPins() {
  $(`.${classPrefix}__mappinWrap`).remove()
}

function putPin(dest) {
  const pinStyle = document.defaultView.getComputedStyle(document.querySelector(`.${classPrefix}`))
  const pinW = pinStyle.getPropertyValue('--pinW').replace("px", "")
  const pinH = pinStyle.getPropertyValue('--pinH').replace("px", "")

  const cat = $(`.${classPrefix} input[name="category"]:checked`).val();

  const destKey = destToKey(dest.name)

  const $pin = $(`
        <div class="${classPrefix}__mappinWrap page-energy_works__mapPin --${cat}" style="opacity: 0; top: calc(${dest.position.y}% - ${pinH}px);left: calc(${dest.position.x}% - ${pinW / 2}px);">
            <svg class="${classPrefix}__mappin" data-destination="${destKey}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 28">
              <g data-name="pin" transform="translate(-145 -3724)">
                <path data-name="pinBase" d="M10,0A10,10,0,0,1,20,10c0,5.523-10,18-10,18S0,15.523,0,10A10,10,0,0,1,10,0Z" transform="translate(145 3724)" />
                <circle data-name="pinCircle 35" cx="3" cy="3" r="3" transform="translate(152 3731)" fill="#fff"/>
              </g>
            </svg>
        </div>`)

  $(`.${classPrefix}__map`).append($pin)
  $pin.ready(()=> {
    const anim = $pin.get()[0].animate([
      {
        transform: `translateY(-10px)`,
        opacity: 0,
      },
      {
        transform: `translateY(0px)`,
        opacity: 1,
      },
    ], {
      duration: 300,
      delay: Math.floor(Math.random() * 200),
    })
    anim.addEventListener('finish', ()=> {
      $pin.css("opacity", "")
    }, { once: true})
  })
}

function appendCountryList(dest) {
  const destKey = destToKey(dest.name)
  const $li = $(`<li  data-destination="${destKey}">${dest.name}</li>`)
  $(`.${classPrefix}__countryList`).append($li)
}
function clearCountryList() {
  $(`.${classPrefix}__countryList > li`).remove()
}

function destToKey(destName) {
  if (!destToKey.cache) {
    destToKey.cache = {}
  }
  if (destToKey.cache[destName] !== undefined ) {
    return destToKey.cache[destName]
  }
  Object.keys(destinations).forEach(key => {
    if(destinations[key].name === destName) {
      destToKey.cache[destName] = key
    }
  })
  return destToKey.cache[destName]
}

function openModal(modalData) {
  const modal = getModal(modalData)
  const $modal = $(modal)
  $(`.${classPrefix}__map`).append($modal)
  $modal.ready(()=> {
    const anim = $modal.get()[0].animate([
      {
        opacity: 0,
      },
      {
        opacity: 1,
      },
    ], {
      duration: 300,
    })
    anim.addEventListener('finish', ()=> {
      $modal.css("opacity", "")
    }, {once: true})
  })
}
function closeModal() {
  $(`.${classPrefix}__mappinWrap`).removeClass('active')
  $(`.${classPrefix}__modal`).fadeOut("fast", function() {
    $(this).remove()
  })
}
function getModal({destination, product, core, use}) {
  let result = `
            <div class="page-energy_works__map__modal js-productWorldMap__modal" style="opacity: 0;">
                <h4 class="page-energy_works__map__modalTitle">${destination}</h4>
                <ul class="page-energy_works__map__modalBody">
                    <li>
                        <p class="page-energy_works__map__modalLabel">${isEn()?"Product":isCn()?"产品":"製品"}</p>
                        <p class="page-energy_works__map__modalValue">${product}</p>
                    </li>
                    <li>
                        <p class="page-energy_works__map__modalLabel">${isEn()?"Number of units":isCn()?"台数":"基数"}</p>
                        <p class="page-energy_works__map__modalValue">${core}</p>
                    </li>`
  if (use) {
    result += `
                    <li>
                        <p class="page-energy_works__map__modalLabel">${isEn()?"Application":isCn()?"用途":"用途"}</p>
                        <p class="page-energy_works__map__modalValue">${use}</p>
                    </li>`
  }
  result += `
                </ul>
                <button type="button" class="page-energy_works__map__modalClose ${classPrefix}__modalClose" />
            </div>
`
  return result
}

function getModalData(destKey) {
  const catKey = $(`.${classPrefix} input[name=category]:checked`).val()
  const prodKey = $(`.${classPrefix} input[name=product]:checked`).val()
  const appKey = $(`.${classPrefix} input[name=application]:checked`).val()
  let result = {
    product: (products[prodKey])?products[prodKey]:"",
    destination: (destinations[destKey])?destinations[destKey].name:"",
    core: "",
    use: "",
  }
  if (energyWorksData[catKey] && energyWorksData[catKey][prodKey]) {
    if (energyWorksData[catKey][prodKey][appKey]) {
      const coreUse = energyWorksData[catKey][prodKey][appKey][destKey]
      result = {...result, ...{
        core: coreUse.core,
        use: (isEn()&&coreUse.use_en)?coreUse.use_en:(isCn()&&coreUse.use_cn)?coreUse.use_cn:coreUse.use,
      }}
    } else if (energyWorksData[catKey][prodKey].none) {
      const coreUse = energyWorksData[catKey][prodKey].none[destKey]
      result = {...result, ...{
        core: coreUse.core,
        use: (isEn()&&coreUse.use_en)?coreUse.use_en:(isCn()&&coreUse.use_cn)?coreUse.use_cn:coreUse.use,
      }}
    }
  }
  return result
}

const devmode = true
if (devmode) {
  (function getMapPosition() {
    $(`.${classPrefix}__map`).on('click', function(e) {
      const x = Math.round(e.offsetX / $(this).width() * 100 * 100) / 100
      const y = Math.round(e.offsetY / $(this).height() * 100 * 100) / 100
      console.log(`${x}\t${y}`)
    })
  })()
}
