export const destinations = {
  "algeria": {
    "name": "ALGERIA",
    "position": {
      "x": 7.45,
      "y": 59.37
    }
  },
  "argentina": {
    "name": "ARGENTINA",
    "position": {
      "x": 86.45,
      "y": 85.74
    }
  },
  "australia": {
    "name": "AUSTRALIA",
    "position": {
      "x": 42.55,
      "y": 82.42
    }
  },
  "bahrain": {
    "name": "BAHRAIN",
    "position": {
      "x": 20.18,
      "y": 59.7
    }
  },
  "belgium": {
    "name": "BELGIUM",
    "position": {
      "x": 7.82,
      "y": 46.27
    }
  },
  "brazil": {
    "name": "BRAZIL",
    "position": {
      "x": 89.64,
      "y": 74.79
    }
  },
  "bulgaria": {
    "name": "BULGARIA",
    "position": {
      "x": 13.64,
      "y": 50.75
    }
  },
  "canada": {
    "name": "CANADA",
    "position": {
      "x": 73.55,
      "y": 39.47
    }
  },
  "chile": {
    "name": "CHILE",
    "position": {
      "x": 84.27,
      "y": 84.41
    }
  },
  "china": {
    "name": "CHINA",
    "position": {
      "x": 33.64,
      "y": 54.89
    }
  },
  "czech": {
    "name": "CZECH",
    "position": {
      "x": 10.64,
      "y": 46.1
    }
  },
  "czechoslovakia": {
    "name": "CZECHOSLOVAKIA",
    "position": {
      "x": 11.45,
      "y": 46.43
    }
  },
  "europe": {
    "name": "EUROPE",
    "position": {
      "x": 10.45,
      "y": 45.77
    }
  },
  "france": {
    "name": "FRANCE",
    "position": {
      "x": 7.27,
      "y": 48.59
    }
  },
  "germany": {
    "name": "GERMANY",
    "position": {
      "x": 9.09,
      "y": 44.94
    }
  },
  "greece": {
    "name": "GREECE",
    "position": {
      "x": 12.45,
      "y": 52.9
    }
  },
  "india": {
    "name": "INDIA",
    "position": {
      "x": 27.45,
      "y": 62.85
    }
  },
  "indonesia": {
    "name": "INDONESIA",
    "position": {
      "x": 37.36,
      "y": 71.48
    }
  },
  "iran": {
    "name": "IRAN",
    "position": {
      "x": 20.55,
      "y": 55.22
    }
  },
  "iraq": {
    "name": "IRAQ",
    "position": {
      "x": 18.27,
      "y": 55.72
    }
  },
  "italy": {
    "name": "ITALY",
    "position": {
      "x": 10.09,
      "y": 51.41
    }
  },
  "japan": {
    "name": "JAPAN",
    "position": {
      "x": 44,
      "y": 54.23
    }
  },
  "kazakhstan": {
    "name": "KAZAKHSTAN",
    "position": {
      "x": 24.91,
      "y": 47.1
    }
  },
  "kuwait": {
    "name": "KUWAIT",
    "position": {
      "x": 19.36,
      "y": 58.37
    }
  },
  "libya": {
    "name": "LIBYA",
    "position": {
      "x": 10.82,
      "y": 57.71
    }
  },
  "malaysia": {
    "name": "MALAYSIA",
    "position": {
      "x": 33.91,
      "y": 69.32
    }
  },
  "malta": {
    "name": "MALTA",
    "position": {
      "x": 10.73,
      "y": 54.56
    }
  },
  "mexico": {
    "name": "MEXICO",
    "position": {
      "x": 75.82,
      "y": 61.03
    }
  },
  "north_korea": {
    "name": "NORTH KOREA",
    "position": {
      "x": 40.36,
      "y": 52.74
    }
  },
  "norway": {
    "name": "NORWAY",
    "position": {
      "x": 8.82,
      "y": 36.98
    }
  },
  "philippines": {
    "name": "PHILIPPINES",
    "position": {
      "x": 39.45,
      "y": 65.51
    }
  },
  "portugal": {
    "name": "PORTUGAL",
    "position": {
      "x": 4.45,
      "y": 52.24
    }
  },
  "qatar": {
    "name": "QATAR",
    "position": {
      "x": 20.27,
      "y": 59.87
    }
  },
  "russia": {
    "name": "RUSSIA",
    "position": {
      "x": 34.82,
      "y": 33.5
    }
  },
  "saudi_arabia": {
    "name": "SAUDI ARABIA",
    "position": {
      "x": 18,
      "y": 59.04
    }
  },
  "singapore": {
    "name": "SINGAPORE",
    "position": {
      "x": 34.73,
      "y": 71.64
    }
  },
  "slovakia": {
    "name": "SLOVAKIA",
    "position": {
      "x": 11.64,
      "y": 47.43
    }
  },
  "south_korea": {
    "name": "SOUTH KOREA",
    "position": {
      "x": 41,
      "y": 54.39
    }
  },
  "spain": {
    "name": "SPAIN",
    "position": {
      "x": 5.55,
      "y": 52.9
    }
  },
  "taiwan": {
    "name": "TAIWAN",
    "position": {
      "x": 39.09,
      "y": 61.03
    }
  },
  "thailand": {
    "name": "THAILAND",
    "position": {
      "x": 33.64,
      "y": 64.51
    }
  },
  "turkey": {
    "name": "TURKEY",
    "position": {
      "x": 15.55,
      "y": 52.4
    }
  },
  "turkmenistan": {
    "name": "TURKMENISTAN",
    "position": {
      "x": 20.91,
      "y": 50.91
    }
  },
  "uae": {
    "name": "U.A.E.",
    "position": {
      "x": 21.3,
      "y": 60.53
    }
  },
  "usa": {
    "name": "U.S.A.",
    "position": {
      "x": 76,
      "y": 51.91
    }
  },
  "ussr": {
    "name": "U.S.S.R.",
    "position": {
      "x": 34.82,
      "y": 33.5
    }
  },
  "uzbekistan": {
    "name": "UZBEKISTAN",
    "position": {
      "x": 23.82,
      "y": 51.74
    }
  },
  "vietnam": {
    "name": "VIETNAM",
    "position": {
      "x": 35.45,
      "y": 65.01
    }
  },
  "zambia": {
    "name": "ZAMBIA",
    "position": {
      "x": 13.73,
      "y": 77.78
    }
  }
};

export const categories = {
  "vaporizer": "気化器",
  "heat_exchanger": "熱交換器"
};

export const products = {
  "alex": "ALEX",
  "dche": "DCHE",
  "orv": "ORV",
  "ifv": "IFV",
  "hwv": "HWV",
  "cwv": "CWV"
};

export const applications = {
  "air_separation": "Air separation",
  "gas_processing": "Gas processing",
  "lng": "LNG",
  "off_shore_marine": "Off shore/Marine",
  "petrochemical": "Petrochemical",
  "hydrogen_station": "Hydrogen station",
  "hydrogen": "Hydrogen",
  "others": "Others",
  "none": "-"
};

export const energyWorksData = {
  "heat_exchanger": {
    "alex": {
      "air_separation": {
        "algeria": {
          "core": 19,
          "use": "AIR SEPARATION",
          "use_en": "",
          "use_cn": ""
        },
        "brazil": {
          "core": 165,
          "use": "AIR SEPARATION",
          "use_en": "",
          "use_cn": ""
        },
        "chile": {
          "core": 2,
          "use": "AIR SEPARATION",
          "use_en": "",
          "use_cn": ""
        },
        "china": {
          "core": 281,
          "use": "AIR SEPARATION",
          "use_en": "",
          "use_cn": ""
        },
        "germany": {
          "core": 126,
          "use": "AIR SEPARATION",
          "use_en": "",
          "use_cn": ""
        },
        "india": {
          "core": 55,
          "use": "AIR SEPARATION",
          "use_en": "",
          "use_cn": ""
        },
        "indonesia": {
          "core": 3,
          "use": "AIR SEPARATION",
          "use_en": "",
          "use_cn": ""
        },
        "iran": {
          "core": 42,
          "use": "AIR SEPARATION",
          "use_en": "",
          "use_cn": ""
        },
        "japan": {
          "core": 2389,
          "use": "AIR SEPARATION",
          "use_en": "",
          "use_cn": ""
        },
        "libya": {
          "core": 14,
          "use": "AIR SEPARATION",
          "use_en": "",
          "use_cn": ""
        },
        "malaysia": {
          "core": 1,
          "use": "AIR SEPARATION",
          "use_en": "",
          "use_cn": ""
        },
        "mexico": {
          "core": 7,
          "use": "AIR SEPARATION",
          "use_en": "",
          "use_cn": ""
        },
        "north_korea": {
          "core": 17,
          "use": "AIR SEPARATION",
          "use_en": "",
          "use_cn": ""
        },
        "philippines": {
          "core": 7,
          "use": "AIR SEPARATION",
          "use_en": "",
          "use_cn": ""
        },
        "qatar": {
          "core": 1,
          "use": "AIR SEPARATION",
          "use_en": "",
          "use_cn": ""
        },
        "russia": {
          "core": 8,
          "use": "AIR SEPARATION",
          "use_en": "",
          "use_cn": ""
        },
        "saudi_arabia": {
          "core": 30,
          "use": "AIR SEPARATION",
          "use_en": "",
          "use_cn": ""
        },
        "south_korea": {
          "core": 174,
          "use": "AIR SEPARATION",
          "use_en": "",
          "use_cn": ""
        },
        "spain": {
          "core": 10,
          "use": "AIR SEPARATION",
          "use_en": "",
          "use_cn": ""
        },
        "taiwan": {
          "core": 36,
          "use": "AIR SEPARATION",
          "use_en": "",
          "use_cn": ""
        },
        "turkey": {
          "core": 3,
          "use": "AIR SEPARATION",
          "use_en": "",
          "use_cn": ""
        },
        "usa": {
          "core": 14,
          "use": "AIR SEPARATION",
          "use_en": "",
          "use_cn": ""
        },
        "zambia": {
          "core": 2,
          "use": "AIR SEPARATION",
          "use_en": "",
          "use_cn": ""
        }
      },
      "petrochemical": {
        "algeria": {
          "core": 22,
          "use": "ETHYLENE",
          "use_en": "",
          "use_cn": ""
        },
        "brazil": {
          "core": 11,
          "use": "ETHYLENE",
          "use_en": "",
          "use_cn": ""
        },
        "bulgaria": {
          "core": 1,
          "use": "ETHYLENE",
          "use_en": "",
          "use_cn": ""
        },
        "canada": {
          "core": 5,
          "use": "ETHYLENE",
          "use_en": "",
          "use_cn": ""
        },
        "china": {
          "core": 117,
          "use": "ETHYLENE・LPG",
          "use_en": "",
          "use_cn": ""
        },
        "germany": {
          "core": 12,
          "use": "ETHYLENE",
          "use_en": "",
          "use_cn": ""
        },
        "india": {
          "core": 48,
          "use": "ETHYLENE",
          "use_en": "",
          "use_cn": ""
        },
        "indonesia": {
          "core": 11,
          "use": "ETHYLENE・LPG",
          "use_en": "",
          "use_cn": ""
        },
        "iran": {
          "core": 46,
          "use": "ETHYLENE",
          "use_en": "",
          "use_cn": ""
        },
        "japan": {
          "core": 312,
          "use": "ETHYLENE・METHAMOL",
          "use_en": "",
          "use_cn": ""
        },
        "malaysia": {
          "core": 34,
          "use": "ETHYLENE",
          "use_en": "",
          "use_cn": ""
        },
        "qatar": {
          "core": 2,
          "use": "ETHYLENE",
          "use_en": "",
          "use_cn": ""
        },
        "russia": {
          "core": 77,
          "use": "ETHYLENE",
          "use_en": "",
          "use_cn": ""
        },
        "singapore": {
          "core": 17,
          "use": "ETHYLENE",
          "use_en": "",
          "use_cn": ""
        },
        "slovakia": {
          "core": 30,
          "use": "ETHYLENE",
          "use_en": "",
          "use_cn": ""
        },
        "south_korea": {
          "core": 67,
          "use": "ETHYLENE",
          "use_en": "",
          "use_cn": ""
        },
        "taiwan": {
          "core": 21,
          "use": "ETHYLENE",
          "use_en": "",
          "use_cn": ""
        },
        "thailand": {
          "core": 57,
          "use": "ETHYLENE",
          "use_en": "",
          "use_cn": ""
        },
        "usa": {
          "core": 49,
          "use": "ETHYLENE",
          "use_en": "",
          "use_cn": ""
        },
        "uzbekistan": {
          "core": 4,
          "use": "ETHYLENE",
          "use_en": "",
          "use_cn": ""
        },
        "vietnam": {
          "core": 2,
          "use": "ETHYLENE",
          "use_en": "",
          "use_cn": ""
        }
      },
      "gas_processing": {
        "algeria": {
          "core": 14,
          "use": "GAS PROCESSING",
          "use_en": "",
          "use_cn": ""
        },
        "argentina": {
          "core": 10,
          "use": "GAS PROCESSING",
          "use_en": "",
          "use_cn": ""
        },
        "australia": {
          "core": 25,
          "use": "GAS PROCESSING",
          "use_en": "",
          "use_cn": ""
        },
        "brazil": {
          "core": 24,
          "use": "GAS PROCESSING",
          "use_en": "",
          "use_cn": ""
        },
        "canada": {
          "core": 107,
          "use": "GAS PROCESSING",
          "use_en": "",
          "use_cn": ""
        },
        "india": {
          "core": 14,
          "use": "GAS PROCESSING",
          "use_en": "",
          "use_cn": ""
        },
        "iran": {
          "core": 66,
          "use": "GAS PROCESSING",
          "use_en": "",
          "use_cn": ""
        },
        "japan": {
          "core": 280,
          "use": "CO・GAS PROCESSING・HELIUM",
          "use_en": "",
          "use_cn": ""
        },
        "kazakhstan": {
          "core": 17,
          "use": "GAS PROCESSING",
          "use_en": "",
          "use_cn": ""
        },
        "libya": {
          "core": 5,
          "use": "GAS PROCESSING",
          "use_en": "",
          "use_cn": ""
        },
        "malaysia": {
          "core": 58,
          "use": "GAS PROCESSING",
          "use_en": "",
          "use_cn": ""
        },
        "mexico": {
          "core": 1,
          "use": "GAS PROCESSING",
          "use_en": "",
          "use_cn": ""
        },
        "norway": {
          "core": 16,
          "use": "GAS PROCESSING",
          "use_en": "",
          "use_cn": ""
        },
        "portugal": {
          "core": 4,
          "use": "GAS PROCESSING",
          "use_en": "",
          "use_cn": ""
        },
        "qatar": {
          "core": 170,
          "use": "GAS PROCESSING",
          "use_en": "",
          "use_cn": ""
        },
        "singapore": {
          "core": 4,
          "use": "GAS PROCESSING",
          "use_en": "",
          "use_cn": ""
        },
        "saudi_arabia": {
          "core": 5,
          "use": "GAS PROCESSING",
          "use_en": "",
          "use_cn": ""
        },
        "thailand": {
          "core": 12,
          "use": "GAS PROCESSING",
          "use_en": "",
          "use_cn": ""
        },
        "turkmenistan": {
          "core": 5,
          "use": "GAS PROCESSING",
          "use_en": "",
          "use_cn": ""
        },
        "uae": {
          "core": 11,
          "use": "GAS PROCESSING",
          "use_en": "",
          "use_cn": ""
        },
        "usa": {
          "core": 28,
          "use": "GAS PROCESSING",
          "use_en": "",
          "use_cn": ""
        }
      },
      "lng": {
        "algeria": {
          "core": 115,
          "use": "LNG",
          "use_en": "",
          "use_cn": ""
        },
        "australia": {
          "core": 12,
          "use": "LNG",
          "use_en": "",
          "use_cn": ""
        },
        "china": {
          "core": 4,
          "use": "LNG",
          "use_en": "",
          "use_cn": ""
        },
        "indonesia": {
          "core": 6,
          "use": "LNG",
          "use_en": "",
          "use_cn": ""
        },
        "japan": {
          "core": 8,
          "use": "LNG・ORV TEST CORE",
          "use_en": "",
          "use_cn": ""
        },
        "qatar": {
          "core": 106,
          "use": "LNG",
          "use_en": "",
          "use_cn": ""
        },
        "south_korea": {
          "core": 1,
          "use": "LNG",
          "use_en": "",
          "use_cn": ""
        },
        "uae": {
          "core": 15,
          "use": "LNG",
          "use_en": "",
          "use_cn": ""
        },
        "usa": {
          "core": 23,
          "use": "LNG",
          "use_en": "",
          "use_cn": ""
        }
      },
      "others": {
        "brazil": {
          "core": 19,
          "use": "OTHERS",
          "use_en": "",
          "use_cn": ""
        },
        "canada": {
          "core": 16,
          "use": "OTHERS",
          "use_en": "",
          "use_cn": ""
        },
        "china": {
          "core": 1,
          "use": "OTHERS",
          "use_en": "",
          "use_cn": ""
        },
        "india": {
          "core": 2,
          "use": "OTHERS",
          "use_en": "",
          "use_cn": ""
        },
        "indonesia": {
          "core": 1,
          "use": "OTHERS",
          "use_en": "",
          "use_cn": ""
        },
        "iraq": {
          "core": 1,
          "use": "OTHERS",
          "use_en": "",
          "use_cn": ""
        },
        "japan": {
          "core": 223,
          "use": "OTHERS・COMPRESSOR・RNG・SHP",
          "use_en": "",
          "use_cn": ""
        },
        "singapore": {
          "core": 6,
          "use": "OTHERS",
          "use_en": "",
          "use_cn": ""
        },
        "taiwan": {
          "core": 2,
          "use": "OTHERS",
          "use_en": "",
          "use_cn": ""
        },
        "usa": {
          "core": 45,
          "use": "OTHERS",
          "use_en": "",
          "use_cn": ""
        }
      }
    },
    "dche": {
      "gas_processing": {
        "australia": {
          "core": 2,
          "use": "Gas processing_Gas Cooler",
          "use_en": "",
          "use_cn": ""
        },
        "vietnam": {
          "core": 1,
          "use": "Gas processing_Gas Cooler",
          "use_en": "",
          "use_cn": ""
        }
      },
      "off_shore_marine": {
        "brazil": {
          "core": 2,
          "use": "Off shore/Marine",
          "use_en": "",
          "use_cn": ""
        },
        "europe": {
          "core": 8,
          "use": "BOG Cooler・Trim Heater",
          "use_en": "",
          "use_cn": ""
        },
        "south_korea": {
          "core": 17,
          "use": "Re-liquefaction・BOG Cooler・Trim Heater",
          "use_en": "",
          "use_cn": ""
        }
      },
      "hydrogen_station": {
        "china": {
          "core": 18,
          "use": "Hydrogen station_Pre-cooler",
          "use_en": "",
          "use_cn": ""
        },
        "europe": {
          "core": 54,
          "use": "Hydrogen station_Pre-cooler",
          "use_en": "",
          "use_cn": ""
        },
        "japan": {
          "core": 227,
          "use": "After cooler・Pre-cooler",
          "use_en": "",
          "use_cn": ""
        },
        "south_korea": {
          "core": 5,
          "use": "Hydrogen station_Pre-cooler",
          "use_en": "",
          "use_cn": ""
        },
        "usa": {
          "core": 113,
          "use": "Hydrogen station_Pre-cooler, LH2 vaporizer",
          "use_en": "",
          "use_cn": ""
        }
      },
      "lng": {
        "japan": {
          "core": 42,
          "use": "LNG_Vaporizer, Re-liquefaction",
          "use_en": "",
          "use_cn": ""
        }
      },
      "others": {
        "japan": {
          "core": 1,
          "use": "Other_Air cooler",
          "use_en": "",
          "use_cn": ""
        }
      }
    },
  },
  "vaporizer": {
    "orv": {
      "off_shore_marine": {
        "bahrain": {
          "core": 5,
          "use": "Off shore",
          "use_en": "",
          "use_cn": ""
        }
      },
      "lng": {
        "belgium": {
          "core": 4,
          "use": "LNG気化器",
          "use_en": "LNG vaporizer",
          "use_cn": "LNG气化器"
        },
        "chile": {
          "core": 3,
          "use": "LNG気化器",
          "use_en": "LNG vaporizer",
          "use_cn": "LNG气化器"
        },
        "china": {
          "core": 31,
          "use": "LNG気化器",
          "use_en": "LNG vaporizer",
          "use_cn": "LNG气化器"
        },
        "france": {
          "core": 15,
          "use": "LNG気化器",
          "use_en": "LNG vaporizer",
          "use_cn": "LNG气化器"
        },
        "greece": {
          "core": 4,
          "use": "LNG気化器",
          "use_en": "LNG vaporizer",
          "use_cn": "LNG气化器"
        },
        "india": {
          "core": 9,
          "use": "LNG気化器",
          "use_en": "LNG vaporizer",
          "use_cn": "LNG气化器"
        },
        "indonesia": {
          "core": 3,
          "use": "LNG気化器",
          "use_en": "LNG vaporizer",
          "use_cn": "LNG气化器"
        },
        "japan": {
          "core": 94,
          "use": "LNG気化器",
          "use_en": "LNG vaporizer",
          "use_cn": "LNG气化器"
        },
        "kuwait": {
          "core": 12,
          "use": "LNG気化器",
          "use_en": "LNG vaporizer",
          "use_cn": "LNG气化器"
        },
        "mexico": {
          "core": 5,
          "use": "LNG気化器",
          "use_en": "LNG vaporizer",
          "use_cn": "LNG气化器"
        },
        "portugal": {
          "core": 7,
          "use": "LNG気化器",
          "use_en": "LNG vaporizer",
          "use_cn": "LNG气化器"
        },
        "singapore": {
          "core": 7,
          "use": "LNG気化器",
          "use_en": "LNG vaporizer",
          "use_cn": "LNG气化器"
        },
        "south_korea": {
          "core": 22,
          "use": "LNG気化器",
          "use_en": "LNG vaporizer",
          "use_cn": "LNG气化器"
        },
        "spain": {
          "core": 21,
          "use": "LNG気化器",
          "use_en": "LNG vaporizer",
          "use_cn": "LNG气化器"
        },
        "taiwan": {
          "core": 20,
          "use": "LNG気化器",
          "use_en": "LNG vaporizer",
          "use_cn": "LNG气化器"
        },
        "thailand": {
          "core": 10,
          "use": "LNG気化器",
          "use_en": "LNG vaporizer",
          "use_cn": "LNG气化器"
        },
        "turkey": {
          "core": 1,
          "use": "LNG気化器",
          "use_en": "LNG vaporizer",
          "use_cn": "LNG气化器"
        },
        "vietnam": {
          "core": 5,
          "use": "LNG気化器",
          "use_en": "LNG vaporizer",
          "use_cn": "LNG气化器"
        }
      },
      "petrochemical": {
        "japan": {
          "core": 2,
          "use": "フロン蒸発器・エチレン蒸発器",
          "use_en": "Freon vaporizer・Ethylene vaporizer",
          "use_cn": "氟利昂蒸发器・乙烯蒸发器"
        },
        "spain": {
          "core": 2,
          "use": "エチレン蒸発器",
          "use_en": "Ethylene vaporizer",
          "use_cn": "乙烯蒸发器"
        },
        "taiwan": {
          "core": 2,
          "use": "エチレン蒸発器",
          "use_en": "Ethylene vaporizer",
          "use_cn": "乙烯蒸发器"
        }
      }
    },
    "ifv": {
      "lng": {
        "china": {
          "core": 13,
          "use": "LNG気化器",
          "use_en": "LNG vaporizer",
          "use_cn": "LNG气化器"
        },
        "japan": {
          "core": 39,
          "use": "LNG気化器",
          "use_en": "LNG vaporizer",
          "use_cn": "LNG气化器"
        },
        "malta": {
          "core": 2,
          "use": "LNG気化器",
          "use_en": "LNG vaporizer",
          "use_cn": "LNG气化器"
        },
        "thailand": {
          "core": 4,
          "use": "LNG気化器",
          "use_en": "LNG vaporizer",
          "use_cn": "LNG气化器"
        }
      },
      "off_shore_marine": {
        "italy": {
          "core": 3,
          "use": "Off shore",
          "use_en": "",
          "use_cn": ""
        }
      }
    },
    "cwv": {
      "none": {
        "japan": {
          "core": 20,
          "use": "LNG気化器",
          "use_en": "LNG vaporizer",
          "use_cn": "LNG气化器"
        }
      }
    },
    "hwv": {
      "none": {
        "japan": {
          "core": 68,
          "use": "LNG気化器",
          "use_en": "LNG vaporizer",
          "use_cn": "LNG气化器"
        }
      }
    }
  }
};