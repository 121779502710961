//=======================================================
//
// CMSブロックエディタ出力部分のjs処理
//
//=======================================================

const cmsSection = $('.u-cms');

if (cmsSection) {
    // PDFリンクのclassセット
    const link = cmsSection.find('a');
    link.each(function(){
        const href = $(this).attr('href');
        const target = $(this).attr('target');
        // リンク先がPDF && target=_blank -> pdfのclassを付与
        if ( href.includes('.pdf') && target==='_blank' ){
            $(this).addClass('u-pdfLink');
        }
        // !リンク先がPDF && target=_blank -> 別窓リンクのclassを付与
        if ( !href.includes('.pdf') && target==='_blank' ){
            $(this).addClass('u-blankLink');
        }
    })
}