const changeMtssFilter = (val) => {
    const $input = $('.js-searchCategoryFilter input[name="mtssfilter"]')
    if( val === "all") {
        $input.val('').prop('disabled', true)
    } else {
        $input.val(`["${val}"]`).prop('disabled', false)
    }
}

const getCategoryFromURL = () => {
    const param = location.search.slice(1).split('&').reduce((prev,v)=> {
        const w = v.split('=')
        return {...prev, [w[0]]: w[1]}
    }, {})
    if (!param.mtssfilter) return ""

    return decodeURI(param.mtssfilter).slice(2, -2).split('","')[0]
}

$(()=> {
    const currentCat = getCategoryFromURL()
    if (currentCat) {
        $(`.js-searchCategoryFilter input[name="mt_category"][value="${currentCat}"]`).prop('checked', true)
    } else {
        const defaultVal = $(`.js-searchCategoryFilter input[name="mt_category"]:checked`).val()
        changeMtssFilter(defaultVal)
    }

    $('.js-searchCategoryFilter input[name="mt_category"]').on('change', function() {
        changeMtssFilter($(this).val())
    })
})

