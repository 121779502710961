//=======================================================
//
// swiperの設定
//
//=======================================================

import Swiper from 'swiper/bundle';

// トップページ
new Swiper('.js-topSwiper', {
    effect: 'fade',
    speed: 2000,
    loop: true,
    pagination: {
        el: '.page-top__swiperPagination',
        type: 'bullets',
    },
    navigation: {
        nextEl: '.page-top__swiperButton-next',
        prevEl: '.page-top__swiperButton-prev',
    },
    autoplay: {
        delay: 5000,
    },
    fadeEffect: {
        crossFade: true
    },
});



// お知らせ swiper pc:起動、sp：停止
const isTopPage = $('.page-top').length;
const newsOption = {
    spaceBetween: 0,
    speed: 1000,
    loop: false,
    // min-width
    breakpoints: {
        1: {
            slidesPerView: 1,
            enabled: false,
        },
        768: {
            slidesPerView: 2.4,
            enabled: true,
        },
        1261: {
            slidesPerView: isTopPage ? 4.4 : 3,
            enabled: true,
        },
    },
}
new Swiper('#news-swiper', newsOption);

// 知る・学ぶ swiper 
const learnOption = {
    spaceBetween: 20,
    slidesPerView: 1.2,
    speed: 1800,
    // loop: true,
    // autoplay: {
    //     delay: 2500,
    //     disableOnInteraction: false,
    // },
    // min-width
    breakpoints: {
        768: {
            slidesPerView: 2.2,
            spaceBetween: 25,
        },
        1261: {
            slidesPerView: 3.7,
        },
    }
}
new Swiper('#learn-swiper', learnOption);

// 熱交換器事業トップ：ギャラリー
const galleryOption = {
    spaceBetween: 0,
    slidesPerView: 1.6,
    speed: 10000,
    loop: true,
    autoplay: {
        delay: 0,
        disableOnInteraction: false,
    },
    // min-width
    breakpoints: {
        768: {
            slidesPerView: 5.2,
        },
        // 1261: {
        //     slidesPerView: 3.7,
        // },
    }
}

new Swiper('#p-gallery-swiper', galleryOption);


// component p-mainViewSlider
const mainViewSlider = {
    effect: 'fade',
    speed: 3000,
    loop: true,
    allowTouchMove: false,
    autoplay: {
        delay: 3000,
        disableOnInteraction: false,
    }
}
new Swiper('#p-mainViewSlider-swiper', mainViewSlider);
