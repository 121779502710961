//=======================================================
//
// compressor/support サービス拠点マップ
//
//=======================================================

(function(){
    function isSp() {
        return ($(".js-serviceMap__details:visible").length > 0)? true:false;
    }
    $(".worldmap_dotted .area").on("mouseenter", function() {
        if(isSp()) return
        $(`.js-serviceMap__pin[data-target="${$(this).data('target')}"]`).addClass('active')
    })
    $(".worldmap_dotted .area").on("mouseleave", function() {
        if(isSp()) return
        $(`.js-serviceMap__pin[data-target="${$(this).data('target')}"]`).removeClass('active')
    })
    $(".js-serviceMap__pin").on("mouseenter", function() {
        if(isSp()) return
        $(`.worldmap_dotted .area[data-target="${$(this).data('target')}"]`).addClass('active')
        $(`.js-serviceMap__pin[data-target="${$(this).data('target')}"]`).addClass('active')
    })
    $(".js-serviceMap__pin").on("mouseleave", function() {
        if(isSp()) return
        $(`.worldmap_dotted .area[data-target="${$(this).data('target')}"]`).removeClass('active')
        $(`.js-serviceMap__pin[data-target="${$(this).data('target')}"]`).removeClass('active')
    })

    //details
    $(".js-serviceMap__detailTitle").on("click", function() {
        $(this).toggleClass('active').next().slideToggle();
    })

    function resetModal() {
        $(".js-serviceMap__modal").removeClass("active")
    }

    $(".worldmap_dotted .area").each((_,v) => {
        const type = $(v).data('target')
        if (!type) return
        const $content = $(`.js-serviceMap__detail[data-type="${type}"]`).first().clone()
        $(`
            <div data-modal="${type}" class="page-compressor_support__base__modal js-serviceMap__modal">
                <button type="button" class="page-compressor_support__base__modalClose js-serviceMap__close"></button>
            </div>
        `)
            .prepend($content)
            .appendTo('.js-serviceMap')
    })

    $(".worldmap_dotted .area, .js-serviceMap__pin:not(.light)").on("click", function() {
        if(isSp()) return
        const target = $(this).data("target")
        resetModal()
        $(`.js-serviceMap__modal[data-modal="${target}"]`).addClass("active")
    })
    $(".js-serviceMap__close").on("click", function() {
        resetModal()
    })

    const devmode = false
    if (devmode) {
        (function getMapPosition() {
            $(`.js-serviceMap`).on('click', function(e) {
                const x = Math.round(e.offsetX / $(this).width() * 100 * 100) / 100
                const y = Math.round(e.offsetY / $(this).height() * 100 * 100) / 100
                console.log(`{\rx: ${x},\ry: ${y},\rtarget: "modal1",\r},`)
            })
        })()
    }
})()
