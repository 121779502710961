import {isCn} from './i18nUtil'

const targetUrls = ["/catalog/"];
const isTargetPage = () => {
    let flag = false;
    targetUrls.forEach((v) => {
        if (location.pathname.indexOf(v) !== -1) {
            flag = true;
        }
    });
    return flag;
};

const preventRightClick = () => {
    document.oncontextmenu = function () {
        return false;
    };
};

const preventShortcut = () => {
    /*
    chrome
    Action	Mac	Windows / Linux
    Open whatever panel you used last	Command+Option+I	F12 or Control+Shift+I
    Open the Console panel	Command+Option+J	Control+Shift+J
    Open the Elements panel	Command+Shift+C or Command+Option+C	Control+Shift+C

    firefox
    Command Windows macOS Linux
    Open Toolbox (with the most recent tool activated)
        Ctrl + Shift + I
        Cmd + Opt + I
        Ctrl + Shift + I
    Bring Toolbox to foreground (if the Toolbox is in a separate window and not in oreground)
        Ctrl + Shift + I or F12
        Cmd + Opt + I or F12
        Ctrl + Shift + I or F12
    Open Web Console 1
        Ctrl + Shift + K
        Cmd + Opt + K
        Ctrl + Shift + K
    Toggle “Pick an element from the page” (opens the Toolbox and/or focus the Inspector ab)
        Ctrl + Shift + C
        Cmd + Opt + C
        Ctrl + Shift + C

    edge
    Action	Windows/Linux	macOS
    最後に使用したパネルを開く	F12 または Ctrl+Shift+I	Command+Option+I
    コンソール ツールを開く	Ctrl+Shift+J	Command+Option+J
    要素ツールを開く	Ctrl+Shift+C	Command+Shift+C または Command+Option+C

    cmd+option+i
    ctrl+shift+i
    cmd+option+j
    ctrl+shift+j
    cmd+option+c
    ctrl+shift+c
    F12
    cmd+option+k
    ctrl+shift+k
    */
    const keys = ["KeyI", "KeyJ", "KeyC", "KeyK"];
    document.addEventListener("keydown", (e) => {
        if ((e.altKey && e.metaKey) || (e.ctrlKey && e.shiftKey)) {
            if (keys.includes(e.code)) {
                e.preventDefault();
            }
        }
        if (e.code === "F12") {
            e.preventDefault();
        }
    });
};

if (isCn() && isTargetPage()) {
    preventRightClick();
    preventShortcut();
}
