//=======================================================
//
// QA等隣のエレメントの開閉
//
//=======================================================

$('.js-toggleNextElm').on('click', e => {
    e.preventDefault();
    $(e.currentTarget).toggleClass('active');
    $(e.currentTarget).next().slideToggle('fast');
})
