//=======================================================
//
// クッキー許可ボタン
//
//=======================================================

// クッキー許可ボタンをクリックした際に処理(doSomething)を発火
// document.addEventListener('cookieConfirm', doSomething)

(function () {
    const cookieConfirmKey = "cookieConfirm";
    const cookieConfirmMaxAge = 365 * 24 * 60 * 60;

    function isCookieConfirmed() {
        if (isCookieAccepted() || isCookieDenied() || isCookieCanceled()) {
            return true;
        }
        return false;
    }

    function isCookieAccepted() {
        const cookie = document.cookie
            .split("; ")
            .find((row) => row.startsWith(`${cookieConfirmKey}=`));

        if (cookie && cookie.split("=")[1] === "true") {
            return true;
        }
        return false;
    }

    function isCookieDenied() {
        const v = localStorage[cookieConfirmKey];
        if (v) {
            const now = new Date().getTime();
            if (now - v < cookieConfirmMaxAge * 1000) {
                return true;
            } else {
                localStorage.removeItem(cookieConfirmKey);
                return false;
            }
        }

        return false;
    }

    function isCookieCanceled() {
        const v = sessionStorage[cookieConfirmKey];
        if (v) {
            return true;
        }
        return false;
    }

    const evt = new Event("cookieConfirm");

    function acceptCookie() {
        document.cookie = `${cookieConfirmKey}=true;max-age=${cookieConfirmMaxAge};path=/`;
    }
    function denyCookie() {
        const now = new Date().getTime();
        localStorage.setItem(cookieConfirmKey, now);
    }
    function cancelCookie() {
        const now = new Date().getTime();
        sessionStorage.setItem(cookieConfirmKey, now);
    }

    $(() => {
        if (!isCookieConfirmed()) {
            toggleCookieConfirmBox();
            setScrollEvent();
        } else {
            if (isCookieAccepted()) {
                acceptCookie();
            }
            if (isCookieDenied()) {
                denyCookie();
            }
        }

        $(".js-cookieConfirm__accept").on("click", () => {
            acceptCookie();
            removeScrollEvent();
            $("body").removeClass("cookieConfirm");
            document.dispatchEvent(evt);
        });

        $(".js-cookieConfirm__deny").on("click", () => {
            denyCookie();
            removeScrollEvent();
            $("body").removeClass("cookieConfirm");
        });

        $(".js-cookieConfirm__cancel").on("click", () => {
            cancelCookie();
            removeScrollEvent();
            $("body").removeClass("cookieConfirm");
        });

        updateCookieBoxCssVar();
        setResizeEvent();
    });

    const offset = 100;

    function toggleCookieConfirmBox() {
        if (window.scrollY > offset) {
            $("body").addClass("cookieConfirm");
        } else {
            $("body").removeClass("cookieConfirm");
        }
    }

    let scrollTicking = false;
    function scrollEvent() {
        if (!scrollTicking) {
            window.requestAnimationFrame(() => {
                toggleCookieConfirmBox();
                scrollTicking = false;
            });
        }
        scrollTicking = true;
    }

    function setScrollEvent() {
        window.addEventListener("scroll", scrollEvent, false);
    }
    function removeScrollEvent() {
        window.removeEventListener("scroll", scrollEvent, false);
    }

    function updateCookieBoxCssVar() {
        const height = $(".js-cookieConfirm").outerHeight();
        document.documentElement.style.setProperty(
            "--cookieConfirmHeight",
            `${height}px`
        );
    }
    function setResizeEvent() {
        let ticking = false;
        window.addEventListener(
            "resize",
            () => {
                if (!ticking) {
                    window.requestAnimationFrame(() => {
                        updateCookieBoxCssVar();
                        ticking = false;
                    });
                }
                ticking = true;
            },
            false
        );
    }
})();
