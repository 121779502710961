import {isCn} from './i18nUtil'

const url = new URL(location.href)

const isNews = url.pathname.includes('/news/')
const isStudy = url.pathname.includes('/study/')

const getCategory = (pathname) => {
    const match = pathname.replace(/[^/]+.(html|php)/, '').match(/\/[^/]+\/([^/]+)/)
    if (match && match.length >= 2) {
        return match[1]
    }
    return 'all'
}

if (isCn() && (isNews || isStudy)) {
    const cat = getCategory(url.pathname)
    document.addEventListener('DOMContentLoaded', ()=> {
        if (isNews) {
            document.querySelector(`.page-news__tabItem.--${cat}`)
                .classList.add('--active')
        }
        if (isStudy) {
            document.querySelector(`.page-study__category__link.--${cat}`)
                .classList.add('active')
        }
    })
}
